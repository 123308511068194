<template lang="pug">
	section#esqueceuSenha
		.wrapper
			div.esqueceu
				h2 Esqueceu a senha?
				p Enviaremos um link de recuperação de senha para o seu email.
				form(@submit.prevent="sendRecuperar", autocomplete="off")
					.input(:class="{error: errors.email }")
						input(type="email", placeholder="E-mail", v-model="formRecuperar.email")
						span(v-if="errors.email") {{errors.email[0]}}
					button(:disabled="sending") {{ textBotao }}
				span(v-if="message") {{ message }}
</template>

<script>
export default {
	data() {
		return {
			formRecuperar: {
				email: ''
			},
			errors: {},
			message: null,
			sending: false,
			textBotao: "Enviar"
		}
	},
	methods: {
		sendRecuperar() {
			this.textBotao = "Enviando"
			this.message = null;
			this.errors = {};
			this.sending = true;
			this.$axios
				.post(`api/password/email`, this.formRecuperar)
				.then(response => {
					this.textBotao="Enviado!"
					this.formRecuperar = {
						email: ''
					}
					this.message = response.data.message;
				})
				.catch(error => {
					this.sending = false;
					this.textBotao = "Enviar";
					if (error.response.status < 500)
						this.errors = error.response.data.errors
				})
				.finally(() => {
					setTimeout(() => {
						this.sending = false;
						this.textBotao = "Enviar";
					}, 3000)
				})
		}
	},
};
</script>

<style lang="stylus" scoped src="./EsqueceuSenha.styl"></style>
